
import {
  LostCallsReportRange,
  IEmailSubReportLostCalls,
} from "../../interfaces/email-reports";
import { Grid, MenuItem, Select, TextField } from "@material-ui/core";
import MuiTitle from "../misc/form/MuiTitle";
import VipTimeFieldMui from "../VipTimeFieldMui";
import { useState } from "react";
import MuiToggleBoolControlled from "../misc/form/MuiToggleBoolControlled";
import MuiFreeTypeStringList from "../MuiComponents/MuiFreeTypeStringList";
import React from "react";


export interface iEmailReportLostCallsProps {
  data: IEmailSubReportLostCalls;
  submitFunction: (field: string, data: any) => void;
}
const something: any = { ...LostCallsReportRange };
const ranges = Object.keys(something).map((key: any) => ({
  id: key,
  name: something[key],
}));

export const EmailReportLostCalls = ({ data, submitFunction }: iEmailReportLostCallsProps) => {
  const [state, setState] = useState<IEmailSubReportLostCalls>(() => {
    //  console.log(hotDesks)
    const xState = { ...data };
    if (!xState.startTime) {
      xState.startTime = "08:00:00"
      submitFunction("startTime", "08:00:00")
    }
    if (!xState.endTime) {
      xState.endTime = "18:00:00"
      submitFunction("endTime","18:00:00")
    }
    if (!xState.lostCallsOnlyShowTotals) {
      xState.lostCallsOnlyShowTotals = false;
      submitFunction("lostCallsOnlyShowTotals",false)
    }

    if (!xState.lostCallsShowIndividualMissedCalls) {
      xState.lostCallsShowIndividualMissedCalls = false;
      submitFunction("lostCallsShowIndividualMissedCalls", false)
    }

    if (!xState.lostCallsMinimumWaitTime) {
      xState.lostCallsMinimumWaitTime = 5;
      submitFunction("lostCallsMinimumWaitTime", 5)
    }

    if (!xState.lostCallsAgentHourlyCallThreshold) {
      xState.lostCallsAgentHourlyCallThreshold = 5;
      submitFunction("lostCallsAgentHourlyCallThreshold", 5)
    }
    if (!xState.lostCallsReportRange) {
      xState.lostCallsReportRange = "Day";
      submitFunction("lostCallsReportRange", "Day")
    }
    if (!xState.lostCallsShowAgentHourlyPerformance) {
      xState.lostCallsShowAgentHourlyPerformance = false;
      submitFunction("lostCallsShowAgentHourlyPerformance",false)
    }
    if (!xState.lostCallsShowAgentHourlyPerformanceHideNoCalls) {
      xState.lostCallsShowAgentHourlyPerformanceHideNoCalls = false;
      submitFunction("lostCallsShowAgentHourlyPerformanceHideNoCalls", false)
    }
    if (!xState.lostCallsShowAgentHourlyPerformanceHideNotLoggedIn) {
      xState.lostCallsShowAgentHourlyPerformanceHideNotLoggedIn = false;
      submitFunction("lostCallsShowAgentHourlyPerformanceHideNotLoggedIn", false)
    }
    if (!xState.lostCallsUseCDRs) {
      xState.lostCallsUseCDRs = false;
      submitFunction("lostCallsUseCDRs", false)
    }
    return xState;
  });
  const setField = (field: string, value: any) => {
    console.log("subform", field, value);
    const stateUp: any = { ...state };
    stateUp[field] = value;
    setState(stateUp);
    submitFunction(field, value);
  }
  return <Grid container spacing={2}>
    {/* <Grid item xs={12}>
  {JSON.stringify(data)}

</Grid> */}
    <Grid item xs={6}>
      <MuiTitle title="Start Time" />
      <VipTimeFieldMui
        field="startTime"
        value={state.startTime ? state.startTime : "08:00:00"}
        updateField={setField}
      />

    </Grid>
    <Grid item xs={6}>
      <MuiTitle title="End Time" />
      <VipTimeFieldMui
        field="endTime"
        value={state.startTime ? state.endTime : "18:00:00"}
        updateField={setField}
      />
    </Grid>


    <Grid item xs={5}>
      <MuiTitle title="Show the hourly performance of agents on the report?" />
      <MuiToggleBoolControlled value={state.lostCallsShowAgentHourlyPerformance} setValue={(ev) => {
        setField("lostCallsShowAgentHourlyPerformance", !state.lostCallsShowAgentHourlyPerformance);
      }} />

    </Grid>


    <Grid item xs={2}>
      <MuiTitle title="Hide agents who did not log in?" />
      <MuiToggleBoolControlled value={state.lostCallsShowAgentHourlyPerformanceHideNotLoggedIn} setValue={(ev) => {
        setField("lostCallsShowAgentHourlyPerformanceHideNotLoggedIn", !state.lostCallsShowAgentHourlyPerformanceHideNotLoggedIn);
      }} />

    </Grid>


    <Grid item xs={2}>
      <MuiTitle title="Hide agents who did not answer any calls?" />
      <MuiToggleBoolControlled value={state.lostCallsShowAgentHourlyPerformanceHideNoCalls} setValue={(ev) => {
        setField("lostCallsShowAgentHourlyPerformanceHideNoCalls", !state.lostCallsShowAgentHourlyPerformanceHideNoCalls);
      }} />

    </Grid>

    <Grid item xs={2}>
      <MuiTitle title="The number of calls an agent should answer in an hour:" />

      <TextField type="number" variant="outlined" value={state.lostCallsAgentHourlyCallThreshold} onChange={(ev) => {
        setField("lostCallsAgentHourlyCallThreshold", parseInt(ev.target.value))
      }} />

    </Grid>

    <Grid item xs={6}>
      <MuiTitle title="Call Queues To Include" />
      <MuiFreeTypeStringList
        field="lostCallsQueuesToInclude"
        value={data.lostCallsQueuesToInclude}
        updateField={setField}
        placeholder="Queue"
        text="Queues To Include" 
      />
    </Grid>


    <Grid item xs={6}>
      <MuiTitle title="Call Queues To Exclude" />
      <MuiFreeTypeStringList
        field="lostCallsQueuesToExclude"
        value={data.lostCallsQueuesToExclude}
        updateField={setField}
        placeholder="Queue"
        text="Queues To Exclude" 
      />
    </Grid>

    <Grid item xs={6}>
      <MuiTitle title="Agents To Include In The Hourly Performance Report (leave blank for all)" />
      <MuiFreeTypeStringList
        field="lostCallsAgentsToInclude"
        value={data.lostCallsAgentsToInclude}
        updateField={setField}
        placeholder="Agent number e.g 200"
        text="Agents To Include" />
    </Grid>

    <Grid item xs={6}>
      <MuiTitle title="Agents To Exclude From Hourly Performance Report" />
      <MuiFreeTypeStringList
        field="lostCallsAgentsToExclude"
        value={data.lostCallsAgentsToExclude}
        updateField={setField}
        placeholder="Agent number e.g 200"
        text="Agents To Exclude" />
    </Grid>

    <Grid item xs={3}>
      <MuiTitle title="Only Show Totals?" />
      <MuiToggleBoolControlled value={state.lostCallsOnlyShowTotals} setValue={(ev) => {
        setField("lostCallsOnlyShowTotals", !state.lostCallsOnlyShowTotals);
      }} />

    </Grid>


    <Grid item xs={3}>
      <MuiTitle title="Show Individual Missed Calls?" />
      <MuiToggleBoolControlled value={state.lostCallsShowIndividualMissedCalls} setValue={(ev) => {
        setField("lostCallsShowIndividualMissedCalls", !state.lostCallsShowIndividualMissedCalls);
      }} />

    </Grid>

    <Grid item xs={2}>
      <MuiTitle title="Use CDRs?" />
      <MuiToggleBoolControlled value={state.lostCallsUseCDRs} setValue={(ev) => {
        setField("lostCallsUseCDRs", !state.lostCallsUseCDRs);
      }} />

    </Grid>



    <Grid item xs={2}>
      <MuiTitle title="Range" />

      <Select
        variant='outlined'
        fullWidth
        value={state.lostCallsReportRange}
        onChange={(value: any) => {
          setField("lostCallsReportRange", value.target.value)
        }}
      >
        {ranges.map((pay: any) => {
          
          return <MenuItem key={pay.id} value={pay.id}>
          {pay.name}</MenuItem>

        })}
      </Select>
    </Grid>


    <Grid item xs={2}>
      <MuiTitle title="Minimum Wait Time" />

      <TextField type="number" variant="outlined" value={state.lostCallsMinimumWaitTime} onChange={(ev) => {
        setField("lostCallsMinimumWaitTime", parseInt(ev.target.value))
      }} />

    </Grid>

  </Grid>
}
export default EmailReportLostCalls